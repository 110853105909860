<template>
  <div class="card card-body d-flex flex-column">
    <h3 class="text-muted x-text-bold">头像</h3>
    <div class="symbol symbol-50px mb-2">
      <img src="https://preview.keenthemes.com/metronic8/demo1/assets/media/avatars/300-6.jpg" alt=""/>
    </div>

    <h3 class="text-muted x-text-bold">背景头像</h3>
    <div class="symbol symbol-50px mb-2">
      <div class="symbol-label" style="background-image:url('https://preview.keenthemes.com/metronic8/demo1/assets/media/avatars/300-6.jpg')"></div>
    </div>

    <h3 class="text-muted x-text-bold">比例</h3>
    <div class="symbol symbol-50px symbol-2by3 mb-2">
      <img src="https://preview.keenthemes.com/metronic8/demo1/assets/media/avatars/300-6.jpg" alt=""/>
    </div>

    <h3 class="text-muted x-text-bold">文字</h3>
    <div class="d-flex ">
      <div class="symbol symbol-50px mr-2 symbol-circle">
        <div class="symbol-label x-fs-3 x-text-bold text-success ">A</div>
      </div>

      <div class="symbol symbol-50px mr-2 symbol-square">
        <div class="symbol-label x-fs-3 x-text-bold bg-danger text-white">L</div>
      </div>

      <div class="symbol symbol-50px mr-2">
        <div class="symbol-label x-fs-3 x-text-bold text-danger">C</div>
      </div>

      <div class="symbol symbol-50px mr-2">
        <div class="symbol-label x-fs-3 x-text-bold bg-primary text-white">T</div>
      </div>

      <div class="symbol symbol-50px mr-2">
        <div class="symbol-label x-fs-3 x-text-bold text-warning">X</div>
      </div>

      <div class="symbol symbol-50px">
        <div class="symbol-label x-fs-3 x-text-bold bg-info text-white">S</div>
      </div>
    </div>

    <h3 class="text-muted x-text-bold">角标</h3>
    <div class="d-flex mt-1">
      <div class="symbol symbol-50px mr-2">
        <div class="symbol-label x-fs-3 x-text-bold">A</div>
        <span class="symbol-badge badge badge-circle bg-danger x-start-100">3</span>
      </div>

      <div class="symbol symbol-50px mr-2">
        <div class="symbol-label x-fs-3 x-text-bold">A</div>
        <span class="symbol-badge badge badge-circle bg-success x-top-100 x-start-100">3</span>
      </div>

      <div class="symbol symbol-50px mr-2">
        <div class="symbol-label x-fs-3 x-text-bold">A</div>
        <span class="symbol-badge badge badge-circle bg-primary x-start-0">3</span>
      </div>

      <div class="symbol symbol-50px mr-2">
        <div class="symbol-label x-fs-3 x-text-bold">A</div>
        <span class="symbol-badge badge badge-circle bg-warning x-start-0 x-top-100">3</span>
      </div>
    </div>

    <span class="symbol symbol-50px mt-2">
      <span class="symbol-label">
        <!--begin::Svg Icon | path: icons/duotune/finance/fin001.svg-->
        <span class="svg-icon svg-icon-1 svg-icon-gray-600">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M20 19.725V18.725C20 18.125 19.6 17.725 19 17.725H5C4.4 17.725 4 18.125 4 18.725V19.725H3C2.4 19.725 2 20.125 2 20.725V21.725H22V20.725C22 20.125 21.6 19.725 21 19.725H20Z" fill="black"></path>
            <path opacity="0.3" d="M22 6.725V7.725C22 8.325 21.6 8.725 21 8.725H18C18.6 8.725 19 9.125 19 9.725C19 10.325 18.6 10.725 18 10.725V15.725C18.6 15.725 19 16.125 19 16.725V17.725H15V16.725C15 16.125 15.4 15.725 16 15.725V10.725C15.4 10.725 15 10.325 15 9.725C15 9.125 15.4 8.725 16 8.725H13C13.6 8.725 14 9.125 14 9.725C14 10.325 13.6 10.725 13 10.725V15.725C13.6 15.725 14 16.125 14 16.725V17.725H10V16.725C10 16.125 10.4 15.725 11 15.725V10.725C10.4 10.725 10 10.325 10 9.725C10 9.125 10.4 8.725 11 8.725H8C8.6 8.725 9 9.125 9 9.725C9 10.325 8.6 10.725 8 10.725V15.725C8.6 15.725 9 16.125 9 16.725V17.725H5V16.725C5 16.125 5.4 15.725 6 15.725V10.725C5.4 10.725 5 10.325 5 9.725C5 9.125 5.4 8.725 6 8.725H3C2.4 8.725 2 8.325 2 7.725V6.725L11 2.225C11.6 1.925 12.4 1.925 13.1 2.225L22 6.725ZM12 3.725C11.2 3.725 10.5 4.425 10.5 5.225C10.5 6.025 11.2 6.725 12 6.725C12.8 6.725 13.5 6.025 13.5 5.225C13.5 4.425 12.8 3.725 12 3.725Z" fill="black"></path>
          </svg>
        </span>
        <!--end::Svg Icon-->
      </span>
    </span>

  </div>
</template>

<script>
export default {
  name: 'example',
}
</script>

<style lang="scss" scoped>
</style>
